import React from 'react';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/pet-odor-removal';

const PetOdorRemovalPage = () => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Pet Odor Removal</h1>
      <p>
        Pet odor issues can have a notoriously bad impact on quality of life. At
        their worst, the odors can be overpowering. At their best, they are
        still annoying and can discourage you from having friends or family in
        for a visit. Unfortunately, they can also be incredibly difficult to
        remove. Many over-the-counter products exist to deal with these
        problems. Sometimes they help. Sometimes they don’t.
      </p>
      <p>
        When your go-to pet treatment product fails, we offer some options that
        might help deal with the issue.
      </p>
      <p>
        First, it’s important to know exactly what type of pet odor issue you’re
        dealing with. Pet odor issues can be:
      </p>
      <ul>
        <li>
          Body oils. This creates the <em>wet dog smell.</em>
        </li>
        <li>Urine.</li>
        <li>Other body fluids.</li>
      </ul>
      <p>
        Whichever type of pet odor issue you have will require a different
        treatment plan.
      </p>

      <h2>Body oils</h2>
      <p>
        When body oils are causing an odor problem, they are almost always from
        dogs. Dog oils can cause a very strong odor, and they are incredibly
        difficult to remove. Standard cleaning treatments are often not
        effective at dealing with this problem. This means that getting your
        carpets cleaned by standard-means probably won’t do the trick.
      </p>
      <p>
        To help with this, we will often use solvent and enzyme additives in our
        cleaning process. These help break down the oils and remove a higher
        percentage of them resulting in reduced odors.
      </p>
      <p>
        It’s also worth noting that pet oils aren’t likely to only be on your
        carpets. They will also be on your baseboards and furniture. It’s
        essential to deal with the oils in all locations to fix the odor issue.
      </p>
      <h2>Pet Urine</h2>
      <p>
        Pet urine is another common cause of strong pet odors. Dealing with pet
        urine issues is a more involved process than dealing with body oils.
        First, we need to know where the urine spots are. When this isn’t
        obvious, it might be necessary to use a black light during the night to
        identify the locations of the urine spots.
      </p>
      <p>
        Once we know where the urine spots are, we can decide how best to
        approach the problem. The first step is always to thoroughly clean the
        carpet. While doing this, we will use an enzyme-based pet-urine
        treatment. In some situations, this is enough to deal with the issue.
      </p>
      <p>
        If the urine odors persist, we will typically recommend following the
        cleaning with either a pet-topical treatment or a pet-flush treatment.
      </p>
      <p>
        The pet-topical treatment involves spraying an enzyme-based urine
        deodorizer over the freshly-cleaned carpet. This is the preferred course
        of action when the problem isn’t too severe or is too widespread for a
        pet-flush treatment. This is a very affordable treatment that often
        helps reduce or eliminate the urine odor.
      </p>
      <p>
        A pet-flush treatment is often recommended in situations where there are
        very few pet urine locations, but they are severe. This process involves
        dumping bucket-fulls of a mixture of hot water and an enzyme treatment
        on the affected areas--then following up by extracting the mixture and
        urine from the carpet using our water damage restoration equipment. This
        is a more involved--and more expensive--process, but is still cheaper
        than replacement of the carpet.
      </p>
      <p>
        If none of these options are viable, carpet replacement is the only way
        to deal with the issue.
      </p>
      <h2>Other Body Fluids</h2>
      <p>
        Other pet problems are typically removable with a thorough carpet
        cleaning. If the problem is severe, a deodorizer may be applied to help.
        Sometimes the spot or stain will need to be treated. But the vast
        majority of the time, these issues can be corrected with a standard
        cleaning process.
      </p>
    </Wrapper>
  </Layout>
);

export default PetOdorRemovalPage;
